import Link from "next/link";

function Services(props) {
	return(
		<>
			{/* Skills */}
			<section className="">
				<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
					{ props.data.map (service =>(
							<div key={service.id} className="p-5 bg-gray-500 hover:bg-gray-900 rounded-md hover:backdrop-blur-2xl bg-opacity-20 hover:bg-opacity-30 sm:w-5/6 w-100">
                <span className="block text-xl font-semibold">{service.name}</span>
								<span className="block">Starting: {service.initial}</span>
                {/* <Link href="/contact-me"><h5 className="italic text-lg hover:underline underline-offset-4">Get A Free Quote</h5></Link> */}
							</div>
						))
					}
				</div>
        <button className="hover:border-black"><Link href={"/contact-me"}>LET&apos;S CONNECT RIGHT AWAY!</Link></button>
			</section>
		</>
	)
}

export default Services;